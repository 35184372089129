<template>
    <div class="">


        <!-- Page Banner Start-->
        <PageBanner
            :pgLoading="pgLoading"
            :background="(page.image) ? page.image.url : ''"
            :currentPage="($i18n.locale == 'ar') ? page.title_ar : page.title_en">
        </PageBanner>
        <!-- Page Banner End -->


        <!-- Testimonials Start -->
        <section class="padding">
          <div class="container">

            <masonry
                v-if="pgLoading"
                :cols="{default: 3, 1000: 3, 700: 2, 400: 1}"
                :gutter="{default: '30px', 700: '15px'}"
                >
                <div v-for="(item, index) in 6" :key="index">
                  <div class="cbp-item">
                       <div class="cbp-caption-defaultWrap">
                          <div class="testinomial_box">
                             <div>
                                <div :class="(index == 1) 
                                    ? 'webkit-animation webkit-365-365' : 'webkit-animation webkit-365-230'"></div>
                             </div>
                             <div class="testinomial_author">
                                <div class="webkit-animation webkit-60-60 bradius"></div>
                                <h4 class="color webkit-animation webkit-50per-50 tgoUp"></h4>
                             </div>
                          </div>
                       </div>
                    </div>
                </div>
            </masonry>

            <masonry
                v-if="!pgLoading"
                :cols="{default: 3, 1000: 3, 700: 2, 400: 1}"
                :gutter="{default: '30px', 700: '15px'}"
                >
                <div v-for="(item, index) in items" :key="index">
                  <div class="cbp-item bottom30">
                       <div class="cbp-caption-defaultWrap">
                          <div class="testinomial_box">

                             <div class="testinomial_desc blue_dark  text-center">
                                <p v-html="($i18n.locale == 'ar') ? item.body_ar : item.body_en"></p>
                                <img 
                                    v-lazy="'/assets/images/quote.webp'" 
                                    src="/assets/images/quote.webp" 
                                    alt="quote" 
                                    title="quote"
                                    class="quote">
                             </div>

                             <div class="testinomial_author">
                                <img 
                                    v-lazy="(item.image) ? item.image.url : ''" 
                                    :src="(item.image) ? item.image.url : ''" 
                                    :alt="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_alt : item.image.en.image_alt : ''" 
                                    :title="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_title : item.image.en.image_title : ''" 
                                    width="59">

                                <h4 class="color" 
                                  v-html="($i18n.locale == 'ar') ? item.title_ar : item.title_en">
                                </h4>

                                <span class="post_img" style="margin-top:5px">
                                  <i v-for="(star, idx) in item.stars"
                                    :key="idx"
                                    class="fa fa-star"
                                    style="color:#ffd250; font-size:18px">
                                  </i>
                                </span>

                             </div>
                          </div>
                       </div>
                    </div>
                </div>
            </masonry>
          </div>
        </section> 
        <!-- Testimonials End --> 


        <LoadMoreButton
            :pgLoading="pgLoading"
            :hasMore="hasMore"
            :btnLoading="btnLoading"
            
            v-on:loadMoreClicked="handleLoadMore">
        </LoadMoreButton>


    </div>
</template>


<script>
export default {
    name: 'List',
    components: {
        PageBanner: () => import('@/components/PageBanner.vue'),
        LoadMoreButton: () => import('@/components/LoadMoreButton.vue'),
    },
    data(){
        return {
            pgLoading: true,
            hasMore: false,
            btnLoading: false,
            btnClicked: false,
            pageNo: 1,

            page: '',
            items: []
        }
    },
    metaInfo() {
        return {
            title: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_title : this.page.meta.en.meta_title : 'meta_title',
            meta: [
                { 
                    name: 'description', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_description : this.page.meta.en.meta_description : 'meta_description'
                },
                { 
                    name: 'keywords', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_keywords : this.page.meta.en.meta_keywords : 'meta_keywords'
                },
                { 
                    name: 'title', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_title : this.page.meta.en.meta_title : 'meta_title'
                }  
            ]
        }
    },
    mounted() {},
    created() {

        this.fetchData(true);
    },
    methods: {

        fetchData(loading=false) {
            if(loading) {
                this.pgLoading = true;
            }
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest',  // security to prevent CSRF attacks
            };
            let vm = this;
            const options = {
                url: window.baseURL+'/testimonials',
                method: 'GET',
                data: {},
                params: {
                    page: this.pageNo,
                    paginate: 3
                }
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.btnLoading = false;
                this.page = res.data.page;
                  
                if(this.btnClicked) {
                    this.items = vm.items.concat(res.data.items);
                } else {
                    this.items = res.data.items;
                }

                this.btnClicked = false;

                if(res.data.paginate.next_page > 0) {
                    this.hasMore = true;
                } else {
                    this.hasMore = false;
                }

            })
            .catch(() => {})
            .finally(() => {});
        },



        handleLoadMore() {
            this.btnClicked = true;
            this.btnLoading = true;
            this.pageNo++;
            this.fetchData();
        },

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
